@import "../_mixins";
@import "../_variables";

.newproduct_container {
  display: grid;
  grid-template-columns: 1.4fr 1fr;
  gap: 20px;
}

.newproduct_image {
  background-color: white;
  height: 170px;
  width: 100%;
  padding: 20px;
  border-radius: 5px;
}

.newproduct_image h3 {
  font-size: 15px;
}

.newproduct_image_outline {
  border: 1px solid rgb(228, 228, 228);
  border-style: dashed;
  height: 105px;
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 105px;
  position: relative;
}

.newproduct_image_outline img {
  width: 520px;
  height: 105px;
}

.image_action_button {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 40px;
  gap: 20px;
  left: 40px;
}

.image_action_button :nth-child(1) {
  border: 1px solid white;
  color: white;
  padding: 8px 80px;
  background: none;
  font-size: 13px;
}

.image_action_button :nth-child(2) {
  background-color: #d0965a;
  padding: 8px 80px;
  border: 1px solid #d0965a;
  font-size: 13px;
}

.selected_image_container {
  position: absolute;
  top: -1px;
}

.newproduct_image_outline p {
  font-size: 13px;
  margin-top: 10px;
}

.import_icon {
  margin: 0 auto;
}

.newproduct_image_outline span {
  color: #d0965a;
  cursor: pointer;
}

.newproduct_form {
  background-color: white;
  height: auto;
  width: 100%;
  margin-top: 20px;
  border-radius: 5px;
  padding: 20px;
}

.newproduct_form h3 {
  font-size: 15px;
}

.product_name_inpt {
  width: 100%;
  padding: 10px;
  border: 1px solid rgb(228, 228, 228);
  border-radius: 3px;
  height: auto;
}

.product_name_inpt2 {
  width: 100%;
  padding: 10px;
  border: 1px solid rgb(228, 228, 228);
  border-radius: 3px;
  height: auto;
  margin-top: 20px;
}

.product_name_select {
  width: 100%;
  padding: 10px;
  border: 1px solid rgb(228, 228, 228);
  border-radius: 3px;
  height: auto;
  color: grey;
}

.product_name_textarea {
  margin-top: 20px;
  width: 100%;
  max-height: 150px;
  border: 1px solid rgb(228, 228, 228);
  padding: 10px;
  border-radius: 3px;
}

.product_name_inpt::placeholder {
  font-size: 13px;
}

.product_name_inpt2::placeholder {
  font-size: 13px;
}

.product_name_textarea::placeholder {
  font-size: 13px;
}

.product_name_select_option::placeholder {
  font-size: 13px;
}

.newproduct_form_label {
  text-align: left;
  width: 50%;
  font-size: 13px;
}

.newproduct_form_label2 {
  text-align: right;
  width: 50%;
  color: #d0965a;
  font-size: 13px;
}

.newproduct_form_label3 {
  text-align: left;
  width: 33.333%;
  font-size: 13px;
}

.newproduct_form_label4 {
  text-align: right;
  width: 33.333%;
  color: #d0965a;
  font-size: 13px;
}

.product_inpts_inner_container {
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
}

.size-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
  .add_size,
  .add_another {
    .text_primary {
      color: #d0965a;
      cursor: pointer;
    }
    h2 {
      font-size: 13px;
    }
  }
}

.add_size.category {
  visibility: hidden;
}

// .milk-types-container {
//   display: flex;
//   flex-wrap: wrap;
//   gap: 12px;
//   input {
//     min-width: 32%;
//   }
// }

.milk-types-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
  :only-child {
    display: block;
    width: 590px;
    margin: 4px 0;
  }
  input {
    min-width: 32%;
  }
}

.product_name_inpt3 {
  padding: 10px;
  border: 1px solid rgb(228, 228, 228);
  border-radius: 3px;
  height: auto;
  color: grey;
}

.rating_star {
  color: gold;
}

.rating_container {
  display: flex;
  align-items: center;
}

.newproduct_details {
  background-color: white;
  height: auto;
  padding: 20px;
  padding-bottom: 60px;
}

.newproduct_details h3 {
  font-size: 15px;
}

.newproduct_details_content {
  display: flex;
  justify-content: space-between;
}

.newproduct_details_content img {
  width: 120px;
  height: 110px;
  border-radius: 5px;
}

.newproduct_details_content_innerd1 {
  display: flex;
  gap: 10px;
}

.newproduct_details_content_innerd1 p {
  font-size: 12px;
}

.rating_tag {
  font-size: 15px;
  margin-top: 10px;
}

.newproduct_details_content_type {
  margin-bottom: 0px;
  margin-top: 5px;
  color: grey;
}

.newproduct_details_content_innerd1 h3 {
  margin-bottom: 0px;
  margin-top: 5px;
}

.newproduct_content_desc_title {
  margin-top: 10px;
}

.newproduct_content_desc {
  font-size: 13px;
  color: grey;
  width: 80%;
}

.size_ptag {
  font-size: 10px;
  color: grey;
  margin-bottom: 10px;
}

.newproduct_size_types_cont {
  display: flex;
  margin-top: 10px;
  gap: 10px;
}

.newproduct_size_types1 {
  padding: 5px 18px;
  gap: 4px;
  border: 1px solid rgb(228, 228, 228);
  border-radius: 32px;
  font-size: 11px;
}

.newproduct_size_types2 {
  padding: 5px 15px;
  gap: 4px;
  border: 1px solid rgb(228, 228, 228);
  border-radius: 32px;
  font-size: 11px;
  white-space: nowrap;
}

.newproduct_details_content_size2 {
  margin-top: 30px;
}

.action_button {
  background-color: white;
  border: 1px solid rgb(228, 228, 228);
  color: #d0965a;
  padding: 8px;
  padding-left: 50px;
  padding-right: 50px;
  gap: 4px;
  border-radius: 32px;
  font-size: 12px;
  cursor: pointer;
}

.action_button2 {
  background-color: #d0965a;
  color: white;
  padding: 8px;
  padding-left: 35px;
  padding-right: 35px;
  gap: 4px;
  border-radius: 32px;
  font-size: 12px;
  cursor: pointer;
}

.action_btn_container {
  display: flex;
  margin-top: 20px;
  gap: 10px;
  justify-content: right;
}

.newproduct_form_container {
  padding-bottom: 40px;
}

@media screen and (max-width: 768px) {
  .milk-types-container {
    :only-child {
      display: block;
      width: 390px;
    }
    input {
      min-width: 32%;
    }
  }
}
