.notification-summary-modal-container {
  border-radius: 14px;
  box-shadow: 0px 0px 10px 1px rgb(207, 204, 204);
  background-color: white;
  position: fixed;
  right: 70px;
  top: 70px;
  z-index: 2;
  height: 52vh;
  width: 350px;

  .modal-header {
    border-bottom: 0.5px solid rgb(222, 223, 223);
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .time_stamp{
    font-size: 10px;
    color: grey;
  }

  .modal-body-area {
    background-color: #fafafa;
    box-sizing: border-box;
  }
  .modal-footer-area {
    padding: 10px;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;

    p {
      font-size: 14px;
      cursor: pointer;
      color: #d0965a;
      font-weight: 500;
    }
  }

  .switch-section {
    font-size: 12px;
    font-weight: 400;
  }

  .item {
    padding: 20px;
    border-radius: 7px;
    margin: 10px 0px;
    display: flex;
    justify-content: space-between;
    h6 {
      font-size: 14px;
    }
    p{
      font-size: 12px;
      color: grey;
    }

    .icon {
      width: 30px;
      height: 30px;
      border-radius: 8px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center top;
      }
    }

    &:hover {
      background-color: var(--bg-light);
    }
  }

  .read-item {
    @extend .item;
    background-color: white;
  }
}

.all-notifications-container {
  display: flex;
  gap: 20px;

  .filter-section {
    width: 30%;
    background-color: white;
    padding: 10px;
    border-radius: 5px;

    header {
      display: flex;
      align-items: center;
      gap: 10px;
      .icon {
        font-size: 25px;
      }
      font-size: 15px;
      font-weight: 500;
    }

    .clear-btn {
      background-color: white;
      margin: 20px 0px;
      color: var(--primary-color);

      border: 1px solid var(--primary-color);
    }
    .filter-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 20px 0px;
      font-size: 14px;
    }
  }

  .notifications-list {
    width: 70%;
    background-color: white;
    padding: 20px;
    border-radius: 5px;

    .item {
      padding: 5px;
      border-radius: 7px;
      margin: 10px 0px;
      padding: 10px;
      display: flex;
      justify-content: space-between;

      .initials {
        width: 35px;
        height: 35px;
        background-color: var(--primary-color);
        color: white;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
      }

      h6 {
        font-size: 14px;
      }

      .icon {
        width: 35px;
        height: 35px;
        border-radius: 8px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center top;
        }
      }

      &:hover {
        background-color: var(--bg-light);
      }
    }
  }
}
