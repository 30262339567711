.profile_container {
  background-color: white;
  height: auto;
  width: 100%;
  padding: 20px;
  margin-top: 20px;
  padding-bottom: 100px;
}

.businessprofile_container_grid {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  width: 80%;
  margin-top: 50px;
}

.business_profile {
  display: flex;
  justify-content: space-between;
  width: 90%;
}

.business_profile_div1 h3 {
  font-size: 17px;
}

.account_profile_div1 {
  width: 50%;
}

.business_profile_div1 p {
  font-size: 14px;
  color: grey;
}

.business_profile_div1 h4 {
  font-size: 15px;
  color: green;
}

.account_profile_div2_image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 1px solid grey;
  margin-bottom: 20px;
}

.account_profile_div2 {
  margin-top: 20px;
  text-align: right;
}

.account_profile_inpts label {
  width: 100%;
  font-size: 12px;
  color: grey;
}

.account_profile_inpts input {
  width: 100%;
  border: none;
  font-size: 15px;
}

.account_profile_inpts {
  margin-top: 20px;
}

// input:focus {
//   outline: none;
// }

.account_profile_inpts_innerd {
  border: 1px solid rgb(228, 228, 228);
  width: 90%;
  padding: 3px 15px;
  border-radius: 5px;
}

.Business_profile_inpts_innerd2 {
  border: 1px solid rgb(228, 228, 228);
  width: 400px;
  padding: 3px 15px;
  border-radius: 5px;
  border-radius: 5px;
}

.Business_profile_inpts_innerd3 {
  border: 1px solid rgb(228, 228, 228);
  width: 400px;
  padding: 3px 15px;
  border-radius: 5px;
  border-radius: 5px;
  margin-top: 15px;

  .input-container {
    display: flex;
    align-items: center;
    gap: 5px;
    input {
      width: 15px;
      height: 15px;
    }
    p {
      font-size: 12px;
      margin-top: 15px;
    }
  }
}

.Business_profile_inpts_innerd2 label {
  font-size: 13px;
  width: 100%;
}

.Business_profile_inpts_innerd3 label {
  font-size: 13px;
  width: 100%;
}

.Business_profile_inpts_innerd2 input {
  border: none;
  width: 100%;
}

.Business_profile_inpts_innerd3 input {
  border: none;
  width: 100%;
}

.Business_profile_inpts_innerd3 select {
  border: none;
  width: 100%;
}

.text-danger {
  color: red;
}

.country_select_container {
  width: 98.5%;
  margin-top: 15px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.country_select_container_div1 {
  border: 1px solid rgb(228, 228, 228);
  padding: 12px 17px;
  display: flex;
  align-items: center;
  width: 25%;
  border-radius: 5px;
}

.business_profile_inpts_innerd3 {
  border: 1px solid rgb(228, 228, 228);
  width: 85%;
  padding: 9px 17px;
  border-radius: 5px;
}

.newbusiness_image_outline {
  border: 1px solid rgb(228, 228, 228);
  border-style: dashed;
  height: 105px;
  width: 280px;
  display: flex;
  text-align: center;
  justify-content: center;
  padding: 10px;
  align-items: center;
  height: 105px;
  position: relative;
  border-radius: 5px;
  margin-right: 30px;
}

.newbusiness_image_outline_innerd {
  margin: 0 auto !important;
}

.click_to_upload_ptag {
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 0px;
  color: grey;
}

.image_icon {
  margin: 0 auto;
  margin: 0 auto !important;
}

.company_content {
  display: flex;
  align-items: center;
}

.company_logo_selected img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.company_logo_selected {
  margin-right: 30px;
}

.image_type {
  font-size: 12px;
  color: grey;
  margin-top: 5px;
}

.image_icon_cont {
  height: 25px;
  width: 25px;
  background-color: rgb(240, 240, 240);
  border-radius: 50%;
  margin: 0 auto;
}

.newbusiness_image_outline_innerd span {
  color: #d0965a;
  cursor: pointer;
}

.business_profile_inpts_innerd3 label {
  width: 100%;
}

.business_profile_inpts_innerd3 input {
  width: 100%;
  border: none;
}

.country_code {
  font-size: 13px;
}

.profile_container_hr {
  margin-top: 75px;
  border-top: none;
  border-bottom: 1px solid rgb(176, 176, 176);
}

.businessprofile_container_grid2 {
  // border: 1px solid black;
  width: 75%;
  display: flex;
  justify-content: space-between;
  // grid-template-columns: 1fr 1fr;
  align-items: flex-start;

  margin-top: 50px;
}

// .account_profile_inpts_innerd4_inpt{
//   width: 400px;
//   padding: 9px;
//   border-radius: 5px;
//   border: 1px solid rgb(228, 228, 228);
// }

// .account_profile_inpts_innerd4_inpt{
//   width: 400px;
//   padding: 9px;
//   border-radius: 5px;
//   border: 1px solid rgb(228, 228, 228);
// }

// .account_profile_inpts_innerd5{
// margin-top: 15px;
// }

// .account_profile_inpts_innerd4_inpt::placeholder{
// font-size: 13px;
// }

.account_profile_div3 h3 {
  font-size: 17px;
}

.account_profile_div3 p {
  font-size: 14px;
  color: grey;
}

.account_profile_div3 h4 {
  font-size: 15px;
  color: green;
}

.account_profile2 {
  display: flex;
  justify-content: space-between;
}
