.settings_type_cont {
  display: flex;
  align-items: center;
  width: 45%;
  gap: 40px;
}

.settings_type_cont_btn {
  font-size: 13px;
  color: black;
  cursor: pointer;
}

.active_btn {
  padding: 8px 40px;
  background-color: #d0965a;
  color: white;
  border-radius: 32px;
  border: none;
  font-size: 13px;
}

.profile_container {
  background-color: white;
  height: auto;
  width: 100%;
  padding: 20px;
  margin-top: 20px;
  padding-bottom: 100px;
}

.profile_container_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}

.account_profile {
  display: flex;
  justify-content: space-between;
}

.account_profile_div1 h3 {
  font-size: 17px;
}

.account_profile_div1 p {
  font-size: 14px;
  color: grey;
}

.account_profile_div1 h4 {
  font-size: 15px;
  color: green;
}

.account_profile_div2_image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 1px solid grey;
  margin-bottom: 20px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.account_profile_div2 {
  margin-top: 20px;
  text-align: right;
}

.edit_photo {
  padding: 5px 30px;
  background-color: none;
  border: 1px solid grey;
  border-radius: 3px;
  font-size: 13px;
  cursor: pointer;
  // margin-top: 20px;
}

.edit_photo:hover {
  background-color: rgb(236, 236, 236);
}

.hidden-file-input {
  display: none;
  opacity: 0;
}

.account_profile_inpts label {
  width: 100%;
  font-size: 12px;
  color: grey;
}

.account_profile_inpts input {
  width: 100%;
  border: none;
  font-size: 15px;
}

.account_profile_inpts {
  margin-top: 20px;
}

input:focus {
  outline: none;
}

.account_profile_inpts_innerd {
  border: 1px solid rgb(228, 228, 228);
  width: 90%;
  padding: 3px 15px;
  border-radius: 5px;
}

.account_profile_inpts_innerd2 {
  border: 1px solid rgb(228, 228, 228);
  width: 90%;
  padding: 3px 15px;
  border-radius: 5px;
  margin-top: 15px;
}

.country_select_container {
  width: 98.5%;
  margin-top: 15px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.country_select_container_div1 {
  border: 1px solid rgb(228, 228, 228);
  padding: 12px 17px;
  display: flex;
  align-items: center;
  width: 25%;
  border-radius: 5px;
}

.account_profile_inpts_innerd3 {
  border: 1px solid rgb(228, 228, 228);
  width: 65%;
  padding: 9px 17px;
  border-radius: 5px;
}

.country_code {
  font-size: 13px;
}

.profile_container_hr {
  margin-top: 75px;
  border-top: none;
  border-bottom: 1px solid rgb(176, 176, 176);
}

.profile_container_grid2 {
  // border: 1px solid black;
  width: 75%;
  display: flex;
  justify-content: space-between;
  // grid-template-columns: 1fr 1fr;
  align-items: flex-start;

  margin-top: 50px;
}

.account_profile_inpts_innerd4_inpt {
  width: 400px;
  padding: 9px;
  border-radius: 5px;
  border: 1px solid rgb(228, 228, 228);
}

.account_profile_inpts_innerd4_inpt {
  width: 400px;
  padding: 9px;
  border-radius: 5px;
  border: 1px solid rgb(228, 228, 228);
}

.account_profile_inpts_innerd5 {
  margin-top: 15px;
}

.submit-password {
  background-color: #d0965a;
  padding: 5px;
  border-radius: 25px;
  color: white;
  border: none;
  width: 100%;
  margin: 10px 0;
}

.account_profile_inpts_innerd4_inpt::placeholder {
  font-size: 13px;
}

.account_profile_div3 h3 {
  font-size: 17px;
}

.account_profile_div3 p {
  font-size: 14px;
  color: grey;
}

.account_profile_div3 h4 {
  font-size: 15px;
  color: green;
}

.account_profile2 {
  display: flex;
  justify-content: space-between;
}
