.profile-settings-container {
  .input-section {
    margin: 10px 0px;
  }
}

.password-settings-container {
  .buttons {
    justify-content: flex-start;
  }

  .input-section {
    .input {
      position: relative;
    }

    .eye {
      position: absolute;
      top: 35%;
      right: 10px;
    }
  }
}

.settings_summary_modal_container {
  height: auto;
  background-color: white;
}

.profile_picture_cont {
  display: flex;
  align-items: center;
  padding: 10px;
  padding-right: 20px;

  .user-details {
    .user-name {
      font-size: 15px;
      font-weight: 600;
    }
    .profile_picture_cont_email {
      font-size: 12px;
      font-weight: 400;
    }
  }

  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  p {
    margin-bottom: 0px;
  }
}

.items_list {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 15px;
  cursor: pointer;

  p {
    margin-bottom: 0px;
    color: black;
  }
}

.settings_icon {
  color: black;
}

.settings_icon2 {
  color: red;
}

.active_item:hover {
  background-color: rgba(43, 235, 43, 0.155);

  .settings_icon {
    color: rgba(11, 148, 11, 0.866);
  }

  .settings_icon2 {
    color: rgba(11, 148, 11, 0.866);
  }

  p {
    color: rgba(11, 148, 11, 0.866);
  }
}

.item_link {
  text-decoration: none;
}
