@import "../mixins";
@import "../variables";

.navigation-container {
  position: fixed;
  // left: 20%;
  @include flex-between-center;
  height: 70px;
  right: 0;
  padding: 10px 20px;
  background-color: white;
  z-index: 1;

  .close-open-sidebar {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .profile-section {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 0;

    .profile-settings {
      height: 100%;
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      .avatar {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        background-color: var(--primary-color);

        img {
          width: 100%;
          object-fit: cover;
          object-position: center top;
          border-radius: 100%;
        }
      }
    }

    .notification-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;
    }

    .search-container {
      position: relative;
      input {
        width: 182px;
        height: 40px;
        border-radius: 5px;
        color: #a9a29b;
        border: 1px solid #a9a29b;
        padding: 10px;
      }
      input:focus {
        // border: none !important;
        // outline: none !important;
      }

      input::placeholder {
        margin-left: 10px;
      }
      .search-glass {
        position: absolute;
        top: 35%;
        right: 2%;
      }
    }
  }
}
