.input-containers {
  height: 54px;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid rgb(174, 174, 174);
  padding: 5px;

  input,
  input:focus {
    border: none !important;
    outline: none !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    z-index: 2;
    background-color: transparent;
    color: rgb(86, 86, 86) !important;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
    color: rgb(212, 17, 17) !important;
  }

  .input-label {
    font-size: 12px;
    color: grey;
  }
}
