@mixin buttonStyle($bg, $color, $border) {
  width: 100%;
  padding: 20px;
  border: $border;
  outline: none;
  border-radius: 32px !important;
  background-color: $bg;
  color: $color;
  font-weight: 500;
  font-size: 14px;
  box-shadow: 0px 0px 0px 1px rgba(128, 128, 128, 0.3);
  margin: 10px 0px;
}

@mixin inputStyle() {
  width: 100%;
  padding: 7px 10px;
  outline: none;
  border: none;
  border-radius: 7px;
  box-shadow: 0px 0px 0px 1px rgba(128, 128, 128, 0.3);
  border: 1px solid transparent;
  transition: all 0.3s linear;
  background-color: transparent;
  margin: 0 !important;
  // &:focus{
  //     border: 1px solid var(--primary-color);
  // }
}
@mixin flex-center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@mixin flex-evenly-center {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
@mixin flex-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@mixin flex-none-center {
  display: flex;
  align-items: center;
}
@mixin flex-center-none {
  display: flex;
  justify-content: center;
}
@mixin flex-none-end {
  display: flex;
  align-items: flex-end;
}
@mixin flex-end-center {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@mixin flex-none-start {
  display: flex;
  align-items: flex-start;
}
@mixin flex-between-start {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@mixin flex {
  display: flex;
}
