@import "../mixins";
@import "../variables";

.card-container {
  width: 32%;
  height: 100%;
  background-color: white;
  border-radius: 8px;
  padding: 15px 30px;
  border: 1px solid #e0ddd9;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  .icon {
    height: 30px;
    width: 30px;
    border-radius: 100%;
    background-color: #fdfaf7;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      object-fit: contain;
      border-radius: 100%;
    }
  }

  .title {
    font-size: 18px;
    color: #000000;
    font-weight: 500;
    @include flex-none-center;
    margin: 10px 0px;
  }

  .card-footer {
    // height: 40%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .count {
      font-weight: 500;
      font-size: 36px;
    }

    .percentage {
      font-weight: 400;
      font-size: 16px;
      width: 66px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 32px;
      background-color: #ecfdf3;
      color: #000;
    }
  }
}

.summary-cards-container {
  display: flex;
  // gap: 20px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 161px;
}
