@import "../mixins";
@import "../variables";

.page-header-container {
  height: 38px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  header {
    .page-header-title {
      font-weight: 500;
      font-size: 30px;
      line-height: 38px;
    }
  }

  .page-header-options {
    display: flex;
    gap: 5px;
    align-items: center;
    .page-option-card {
      height: 36px;
      padding: 0 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 32px;
      margin: 0 !important;
      border: 1px solid rgb(210, 210, 210);
      cursor: pointer;
      h3 {
        font-size: 14px;
        font-weight: 500;
        margin: 0 !important;
      }
    }

    .page-option-card-active {
      @extend .page-option-card;
      border: 1px solid $primaryColor;
      background-color: $primaryColor-100;
    }
  }
}
