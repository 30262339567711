.store_settings {
  background-color: white;
  height: auto;
  width: 100%;
  padding: 20px;
  margin-top: 20px;
  padding-bottom: 100px;
}

.store_settings_header {
  width: 100%;
  padding: 20px;
  border: 1px solid rgb(176, 176, 176);
  border-radius: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.store_settings_outline {
  width: 100%;
  padding: 20px;
  border: 1px solid rgb(176, 176, 176);
  border-radius: 5px;
}

.store_settings_header_div1 {
  display: flex;
  // align-items: center;
  gap: 70px;
}

.store_settings_header_div1 h3 {
  font-size: 13px;
}

.store_settings_header_div1 span {
  padding: 5px 17px;
  border-radius: 32px;
  background-color: rgba(255, 242, 242, 0.796);
  color: red;
  font-size: 13px;
}

.store_settings_header_div1 p {
  font-size: 13px;
}

.store_settings_header_div1_time {
  display: flex;
  align-items: center;
  gap: 30px;

  p {
    margin: 0 !important;
  }
}

.store_settings_header_div2 {
  display: flex;
  justify-content: right;
}

.pref-btns {
  display: flex;
  gap: 5px;

  .pref-actions {
    padding: 3px;
    border-radius: 20px;
    width: 60px;
    cursor: pointer;
  }
  .pref-cancel {
    border: 1px solid grey;
  }

  .pref-save {
    border: none;
    background-color: #d0965a;
    color: white;
  }
}

.store_settings_header_div2_edit {
  background-color: #d0965a;
  padding: 3px 17px;
  gap: 4px;
  border-radius: 32px;
  font-weight: 100;
  margin-right: 10px;
  display: flex;
  align-items: center;
  font-size: 13px;
  color: white;
  cursor: pointer;
}

.store_settings_header_div2_p {
  font-size: 13px;
  margin-top: 10px;
  color: black;
  font-weight: 200;
}

.store_settings_action_p {
  font-size: 10px;
  margin-top: 10px;
  color: black;
  font-weight: 200;
  margin-bottom: 0px;
}

.store_settings_action_div1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  margin-top: 10px;
}

.store_settings_action {
  width: 60%;
  // border: 1px solid black;
}

.store_settings_action2 {
  width: 60%;
  margin-top: 20px;
}

.set-time {
  width: 100px;
  font-size: 15px;
}

.time_to {
  font-size: 12px;
  color: grey;
}

.time {
  font-size: 15px;
  width: 100px;
}

.store_settings_action_open {
  display: flex;
}

.open {
  font-size: 13px;
  width: 70px;
}

.store_settings_header2 {
  width: 100%;
  padding: 20px;
  border: 1px solid rgb(176, 176, 176);
  border-radius: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.store_settings_header2 h3 {
  font-size: 13px;
}

.store_settings_outline2 {
  width: 100%;
  padding: 20px;
  border: 1px solid rgb(176, 176, 176);
  border-radius: 5px;
  margin-top: 30px;
}

.store_settings_header2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.store_settings_header2_innerd {
  display: flex;
  align-items: center;
  gap: 20px;
}

.store_edit {
  background-color: #d0965a;
  padding: 7px 15px;
  gap: 4px;
  border-radius: 32px;
  font-weight: 100;
  display: flex;
  align-items: center;
  font-size: 13px;
  color: white;
  cursor: pointer;
}

.edit-prep-time {
  width: 100px;
  border-radius: 5px;
  padding: 3px;
  font-size: 15px;
}

.store_delete {
  background-color: rgba(255, 242, 242, 0.796);
  padding: 7px 15px;
  gap: 4px;
  color: red;
  border-radius: 32px;
  font-weight: 100;
  display: flex;
  align-items: center;
  font-size: 13px;
  border: none;
}

.store_settings_header2_p {
  font-size: 13px;
  margin-top: 10px;
  color: black;
  font-weight: 200;
}
