$primary-font: Museo Sans Rounded;

$primaryColor: #d0965a;
$primaryColor-100: #f8efe6;
$mo-info: #ffbb33;
$mo-light: #fff;
$mo-success: #32d16d;
$mo-danger: #f42f4b;
$mo-grey: #d1d1d1;
$mo-grey-light: #fafafa;
$mo-bg-grey: #f9f9f9;
$mo-blue: #216fed;
