@import "../mixins";
@import "../variables";

.sidebar-container {
  padding: 20px;
  background-color: #ffffff;
  height: 100vh;
  position: fixed;
  overflow-y: scroll;

  .logo-section {
    width: 60%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .logo-container {
      height: 101.44px;
      width: 95.62px;

      img {
        width: 100%;
      }
    }

    .logo-collapsed {
      width: 50px !important;
      height: 50px !important;
      img {
        width: 100%;
      }
    }
    .sidebar-heading {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
  }

  .sidebar-items {
    margin-top: 30px;
    .item {
      @include flex-start-center;
      gap: 20px;
      margin: 20px 0px;
      color: #413b35;
      padding: 10px;
      border-radius: 7px;
      font-family: "Lexend";
      background-color: transparent;
      transition: all 0.3s linear;

      user-select: none;
      cursor: pointer;

      .active-icon {
        font-size: 20px;
        @include flex-none-center;
        svg {
          color: #ffffff;
          path {
            stroke: #ffffff;
          }
        }
      }

      .icon {
        @extend .active-icon;
        svg {
          color: #413b35;
          path {
            stroke: #413b35;
          }
        }
      }

      .text {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }

    .active-sidebar-item {
      @extend .item;
      background-color: var(--primary-color);
      color: white;
      .icon {
        svg {
          color: white;
          path {
            stroke: white;
          }
        }
      }
    }
  }
}

::-webkit-scrollbar {
  width: 2px;
  background-color: #0d95891a;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #c6c8d3;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: darken(#c6c8d3, 30%);
}
