.opt-container {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .otp-field {
    display: flex;
    justify-content: center;
    align-items: center;
    .otp-input {
      width: 50px;
      height: 50px;
      border-radius: 5px;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      border: none;
      outline: none;
      background-color: #f0eded;
    }
  }
}
