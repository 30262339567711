.modal-container {
  text-align: center;

  .modal-footer {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
    .footer-btn {
      width: 216px !important;
      height: 40px;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 32px;
      cursor: pointer;
      background-color: transparent !important;
    }
    .action-btn {
      border: 1px solid red;
      color: red;
    }
    .success-btn {
      border: 1px solid rgb(4, 228, 4);
      color: rgb(4, 228, 4);
    }
    .cancel-btn {
      border: 1px solid grey;
      color: grey;
    }
    .category {
      color: green;
      border: 1px solid green;
    }
  }

  .modal-body {
    width: 100%;
  }
}
