* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.order-table-header,
.order-details-card,
.customer-details-card,
.review-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  padding: 20px;
}

.order-table-banner,
.order-table-banner div,
.drop-down-comp,
.orders-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
}

.order-table-body {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.order-table-banner div {
  gap: 8px;
}

.order-table-banner {
  margin: 12px 0;
}

.drop-down-comp {
  gap: 8px;
  padding: 5px;
  border: 1px solid gray;
  border-radius: 8px;
  cursor: pointer;
}

.drop-down-comp span {
  font-weight: 300;
  padding: 8px 4px;
}

.decor-text {
  font-weight: bold;
}

.order-details-card,
.customer-details-card {
  width: 50%;
  border: 0.5px solid #cdcaca33;
  border-radius: 12px;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.076);
  padding: 12px 0;
}

.customer-details-card {
  width: 40%;
  height: 350px;
}

.order-details-card label,
.method-labels,
.customer-details-card label {
  font-weight: 600;
  padding: 5px 12px;
}

.order-details-table {
  width: 100%;
}

.order-details-table thead,
.payment-summary {
  background: #cdcaca33;
}

.order-details-table td {
  padding: 6px 2px;
  text-align: center;
}

#order-item {
  text-align: left;
  padding: 0 8px;
}

.order-details,
.order-composition {
  display: flex;
  gap: 5px;
  align-items: center;
}

.order-composition,
.reviews-container {
  gap: 2px;
  flex-direction: column;
  align-items: flex-start;
}

.order-ingredients {
  font-size: small;
  font-weight: 100;
}

.order-img-container {
  width: 45px;
  height: 45px;
  border-radius: 15px;
  overflow: hidden;
  object-fit: contain;
}

.order-img-container img {
  width: 100%;
  height: 100%;
}

.payment-summary,
.payment-summary table td,
.payment-method {
  display: flex;
  justify-content: space-between;
  padding: 5px 12px;
}

.payment-summary table td {
  gap: 25px;
}

.customer-details-card {
  padding: 15px 12px;
}

.method-container {
  text-align: center;
  font-weight: 400;
  font-size: small;
}

.method-container img {
  width: 35px;
  height: 25px;
}

.customer-profile {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 8px;
  border-bottom: 1.5px solid #cdcaca33;
}

.profile-img-container {
  width: 45px;
  height: 45px;
  border-radius: 100%;
  object-fit: contain;
  overflow: hidden;
}

.profile-bio {
  display: flex;
  gap: 5px;
  align-items: center;
}

.profile-info {
  display: flex;
  align-items: center;
  gap: 5px;
  height: 40px;
}

.profile-img-container img {
  width: 100%;
  height: 100%;
}

.customer-profile-btn span {
  color: #d09559;
  cursor: pointer;
}

.customer-phone,
.empty-review-container,
.reviews-description {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.customer-phone {
  border-bottom: 1.5px solid #cdcaca33;
  padding: 5px 8px;
}

.reviews-container {
  justify-content: baseline;
  align-items: baseline;
}

.review-container {
  border-bottom: 2px solid #cdcaca33;
  padding: 12px;
  background-color: white;
}

.empty-review-container,
.reviews-description {
  justify-content: center;
  align-items: center;
}

.reviews-description {
  gap: 0px;
}

.reviews-description p {
  font-weight: 500;
}

.reviews-description span {
  font-weight: 200;
}

.review-flex-section {
  display: flex;
  justify-content: space-between;
}

.username-comments,
.comment-body {
  font-size: 15px;
  font-weight: 400;
}

.review-user,
.review-icons {
  display: flex;
  gap: 12px;
  align-items: center;
}

.user-initials {
  border-radius: 50%;
  background-color: #93370d;
  color: white;
  width: 20px;
  height: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
