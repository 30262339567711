@import "../_variables";
@import "../_mixins";

.login-page {
  display: flex;
  justify-content: space-between;
  .auth-left-container {
    width: 50%;
    height: 100vh;
    background-color: $primaryColor-100;
    display: flex;
    align-items: center;

    .auth-left {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 60%;
      height: 80%;
      margin: auto;

      .coffee-cup-container {
        height: 80%;
        img {
          height: 100%;
          object-fit: cover;
        }
      }

      .auth-heading {
        font-size: 36px;
        font-weight: 600px;
        text-align: center;
      }
    }
  }
  .login-container {
    width: 50%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .form-container {
      .form-section {
        width: 60%;
        margin: auto;
        padding: 36px 20px 36px 20px;
        border-radius: 32px 32px 0px 0px;
        box-shadow: -1px 0px 5px 4px rgba(233, 233, 233, 0.72);
        -webkit-box-shadow: -1px 0px 5px 4px rgba(233, 233, 233, 0.72);
        -moz-box-shadow: -1px 0px 5px 4px rgba(233, 233, 233, 0.72);

        .header-text {
          margin-bottom: 20px;
          h2 {
            font-size: 30px;
            font-weight: 600;
          }
        }

        .login-form {
          // margin: auto;
          .password {
            position: relative;
          }
          .input-section {
            input {
              @include inputStyle();
            }
            .eye {
              position: absolute;
              top: 0;
              right: 0;
              margin-top: 12px;
              margin-right: 12px;
            }
          }

          .recovery-section {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            margin-top: 10px;
            .forgot-section {
              color: var(--primary-color);
              font-weight: 500;
            }
          }
          .button-section {
            margin: 30px 0 0 10px;
          }
          .continue {
            @include buttonStyle(var(--primary-color), white, none);
          }
          .forgot-section {
            user-select: none;
            text-align: center;
            font-size: 14px;
            cursor: pointer;
          }
        }

        .verify-otp {
          width: 100%;
          header {
            width: 100%;
            h2 {
              font-size: 30px;
              font-weight: 600;
              line-height: 38px;
              text-align: center;
            }

            p {
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              text-align: center;
              margin-bottom: 20px;
            }
          }

          .resend-code {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin: 5px 0;
          }

          .verify-btn {
            margin-top: 30px;
          }
        }

        .success-page {
          width: 100%;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;

          .success-circle {
            width: 136px;
            height: 136px;
            border-radius: 50%;
            background-color: $primaryColor-100;
            margin: 20px 0;
          }

          h2 {
            font-size: 30px;
            font-weight: 600;
            line-height: 38px;
          }

          button {
            margin: 20px 0 !important;
          }
        }
      }
      .signup {
        user-select: none;
        text-align: center;
        font-size: 14px;
        margin-top: 50px;
        cursor: pointer;
        .blue {
          font-weight: 500;
        }
      }
    }
  }
}

@media (max-width: "780px") {
  .login-container {
    width: 80%;
    .form-section {
      width: 100%;
    }

    .logo-section {
      width: 60%;
    }

    label,
    .forgot-section {
      font-size: 12px !important;
    }
  }
}
