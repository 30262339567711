.customer_card{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  gap: 20px;
}

.customer_card_div1{
  background-color: white;
  height: 100px;
  display: flex;
  gap: 20px;
  padding: 20px;
}

.customer_card_div2{
  background-color: white;
  height: 100px;
  justify-content: space-between;
  display:flex;
  align-items: center;
  padding: 20px;
}




.customer_card_div1_innerd{
border: 1px solid black;
border-radius: 50%;
height: 70px;
width: 70px;
}

.customer_card_user_info h3{
  font-size: 20px;
}

.customer_card_user_info p{
  font-size: 13px;
  color: grey;
  margin-bottom: 0px;
}
.customer_card_user_info span{
  font-size: 13px;
  color: grey;
  
}

.customer_card_div1_innerd img{
  border-radius: 50%;
  height: 100%;
  width: 100%;
}

.bag_icon_container{
  height: 30px;
  width: 30px;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d0975a45;

}

.shopping_icon{
  font-size: 30px;
  color: #d0965a;
}

.dollar_icon{
  color: green;
  font-size: 30px;
}

.dollar_icon_container{
  height: 30px;
  width: 30px;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 128, 0, 0.268);
}


.customer_card_div2_inner_cont{
  display: flex;
  gap: 10px;
}

.line_hr{
  height: 60px;
  
  border: 1px solid grey;
}


