@import "_variables";
@import "_mixins";
// button {
//   @include buttonStyle(var(--primary-color), white, none);
// }
.other-components {
  position: relative;
  top: 73px;
}

.secondary-color {
  color: var(--secondary-color);
}

.pointer {
  cursor: pointer;
}

.green {
  color: var(--success);
}
.blue {
  color: #4c6fe6;
}

.template-with-sidebar-body {
  position: absolute;
  top: 90px;
  left: calc(20% + 20px);
  right: 20px;
}

.input-section {
  margin-top: 30px;
  label {
    font-weight: 500;
  }

  input,
  textarea {
    @include inputStyle();
  }
}

.toggle-container {
  display: inline-block;
  position: relative;
}

.toggle-input {
  display: none;
}

.toggle-label {
  display: block;
  width: 40px;
  height: 20px;
  background-color: #eeeeee;
  border-radius: 20px;
  cursor: pointer;
  position: relative;

  .toggle-slider {
    position: absolute;
    top: 1px;
    left: 1px;
    width: 18px;
    height: 18px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.2s ease-in-out;
  }
}

.toggle-input:checked + .toggle-label {
  background-color: var(--primary-color);
}

.toggle-input:checked + .toggle-label .toggle-slider {
  transform: translateX(20px);
}

.pointer {
  cursor: pointer !important;
}

.history-table {
  width: 100%;
  border-radius: 8px;
  background-color: white;
  // border: 1px solid rgb(212, 212, 212);
  // padding: 20px;
  table {
    width: 100%;
    thead {
      td {
        color: #5a524a;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        padding: 10px;
        width: 145px;
        text-align: left;
      }
    }
    tbody {
      tr {
        width: 100%;
        border-bottom: 1.5px solid rgba(115, 114, 114, 0.097);
        height: 42px;
        cursor: pointer;
        padding: 0 200px !important;
        border-radius: 8px;
        td {
          height: 42px;
          color: #27231f;
          font-size: 12px;
          font-weight: 500;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 180px;
          padding: 0 10px;
        }

        .profile {
          @include flex-none-center;
          gap: 10px;
          .avatar {
            width: 30px;
            height: 30px;
            border-radius: 100%;
            background-color: var(--primary-color);
            overflow: hidden;
            img {
              width: 100%;
              object-fit: cover;
              object-position: center top;
            }
          }

          .name {
            font-weight: 500;
          }
          .username {
            font-size: 10px;
          }
        }

        .status-column {
          gap: 10px;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;

          // .options {
          //   font-size: 25px;
          //   color: #9fa3a8;
          // }
        }

        .all-orders {
          width: 73.79px;
          height: 17.57px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
          text-align: center;
          border-radius: 12px;
          padding: 0 20px;
        }

        .processing-order {
          background: #fef0c7;
          color: #f79009;
        }

        .new-order {
          background-color: #eefedd;
          color: #509b05;
        }

        .completed-order {
          @extend .processing-order;
          color: var(--success-right, #0b0b0b);
          background: #00cc74ad;
        }

        .active-resolved {
          @extend .processing-order;
          color: var(--primary-color);
          border: 1px solid var(--primary-color);
          background: rgba(var(--primary-opacity), 0.1);
        }

        .options {
          position: relative;
        }

        &:hover {
          background: #e8e8e8;
          border-radius: 15px;
        }
      }
    }
  }
}

.breadcrumb {
  font-weight: 400;
  color: #667085;
  gap: 20px;
  font-size: 12px !important;
}

.status-section {
  display: flex;
  align-items: center;
  gap: 10px;
}

.underlay-text {
  font-size: 11px;
  color: #8692a6;
}

.options-menu-global {
  background-color: white;
  padding: 10px;
  width: 150px;
  border-radius: 10px;
  color: #6b7280;
  font-size: 12px;
  position: absolute;
  top: 30px;
  // left: -60px;
  right: 10px;
  box-shadow: 0px 0px 0px 1px rgba(128, 128, 128, 0.3);
  z-index: 1;
  .item {
    @include flex-none-center;
    gap: 10px;
    padding: 10px;
    margin: 5px 0px;
    border-radius: 10px;
    transition: all 0.3s linear;
    user-select: none;
    cursor: pointer;
    &:hover {
      background-color: #f3f4f6;
    }
  }
}

.note {
  color: var(--primary-color);
  font-size: 10px;
  background-color: rgb(var(--primary-opacity), 0.2);
  padding: 4px 10px;
  border-radius: 20px;
}

.bg-primary-10 {
  background-color: rgb(var(--primary-opacity), 0.1);
}
.primary-color {
  color: var(--primary-color);
}

.custom-space-x {
  display: flex;
  align-items: center;
  justify-content: center;

  > *:not(:last-child) {
    margin-right: 1rem;
  }
}

.flex {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.flagged path {
  fill: #d98c3e;
}

.w-full {
  width: 100%;
  border-radius: 8px;
}

.full-textarea {
  width: 100%;
  height: 120px;
  padding: 8px;
  border: none !important;
  outline: none !important;
}

.border-l {
  border-left: 4px solid #d98c3e;
}

.unstyled-btn {
  padding: 1.5px;
  width: 120px !important;
  float: right !important;
  clear: both;
  background: none !important;
  border: none !important;
  outline: none !important;
  color: #d98c3e;
}

.btn.flex {
  display: flex !important;
  justify-content: flex-end;
}
