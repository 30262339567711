@import "../_variables";
@import "../_mixins";

.question-analysis-container {
  display: flex;
  width: 100%;
  gap: 10px;
  margin: 20px 0px;

  h5 {
    font-size: 14px !important;
  }

  .block-section {
    width: 100%;
  }

  .chart-section {
    min-height: 300px;
    background-color: white;
    padding: 10px;
    border-radius: 14px;
    border: 1px solid #e0ddd9;

    .line-chart {
      .chart-divider-container {
        height: 250px;
        width: 2px;
        margin: auto;
        background-color: #cdc8c2;
      }
      .line-chart-container {
        width: 45%;
        height: 100%;
        padding: 20px;

        header {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin: 20px 0;

          h2 {
            font-size: 20px;
            font-weight: 600;
            line-height: 30px;
            text-align: left;
          }

          .durations-select {
            display: flex;
            align-items: center;
            padding: 3.05px 6.1px 3.05px 6.1px;
            gap: 6px;
            border-radius: 18.31px;
            background-color: #fdfaf7;
            .duration {
              font-size: 12px;
              font-weight: 400;
              line-height: 18px;
              cursor: pointer;
            }
            .chart-selected {
              padding: 6.1px 12.21px;
              gap: 6.1px;
              border-radius: 42.72px;
              opacity: 0px;
              background-color: #d0965a !important;
              color: white;
            }
          }
        }
      }
      display: flex;
      justify-content: space-between;
    }
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.top-courses-container {
  * {
    margin: 0;
  }

  .content-width {
    width: 90%;
    padding: 0px 10px;
  }
  margin: 10px 0px;
  padding: 10px;
  background-color: white;
  border-radius: 14px;
  min-height: 400px;
}
.student-queries-container,
.leaderboard-container {
  background-color: white;
  min-height: 300px;
  border-radius: 14px;
  padding: 10px;
  margin-bottom: 10px;
}

.leaderboard-container {
  .filter-area {
    @include flex-between-center;
    font-size: 12px;
    font-weight: 500;
    color: grey;
    padding: 10px 0px;
    border-bottom: 0.5px solid rgb(233, 230, 230);

    .rank-name {
      @include flex-none-center;
      gap: 10px;
    }
  }

  .record-section {
    padding: 10px;
    .item {
      @include flex-between-center;
      margin: 10px 0px;
      padding: 10px 0px;
      border-bottom: 0.5px solid rgb(233, 230, 230);
    }

    section {
      @include flex-none-center;
      gap: 5px;

      .rank {
        background-color: #f5f7f9;
        width: 25px;
        height: 25px;
        @include flex-center-center;
        font-size: 12px;
        border-radius: 3px;
      }

      .up {
        color: #2fb2ab;
      }
      .down {
        color: red;
      }

      .avatar {
        width: 30px;
        height: 30px;
        border-radius: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center top;
          border-radius: 100%;
        }
      }

      .name {
        font-weight: 600;
        font-size: 12px;
      }
    }

    .score {
      color: var(--primary-color);
    }
  }
}

.top-courses-container {
  .heading-area {
    @include flex-between-center;
    font-size: 14px;
    font-weight: 500;
    margin: 20px 0px;
    padding: 10px;

    .recent-orders {
      @include flex-none-center;
      gap: 10px;
      font-size: 20px;
      font-weight: 600;
      line-height: 20px;
      color: #202120;
    }

    .view-all {
      @include flex-none-center;
      gap: 10px;
      font-size: 14px;
      font-weight: 500;
      line-height: 28.01px;
      color: #5a524a;
    }
  }
}
