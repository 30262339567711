.preference {
  background-color: white;
  height: auto;
  width: 100%;
  padding: 20px;
  margin-top: 20px;
  padding-bottom: 100px;
}

.preference_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.preference_grid2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 20px;
}

.preference_grid3 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 20px;
}

.preference_div1 p {
  font-size: 14px;
  color: grey;
}

.preference_div1 h3 {
  font-size: 17px;
}

.preference_div2_innerd {
  display: flex;
  // align-items: center;
  gap: 15px;
}

.preference_div2_innerd2 {
  display: flex;
  gap: 15px;
  margin-top: 15px;
}

.preference_hr {
  margin-top: 75px;
  border-top: none;
  border-bottom: 1px solid rgb(176, 176, 176);
}

.preference_notification_type h3 {
  font-size: 17px;
}

.preference_div2_innerd h3 {
  font-size: 17px;
}

.preference_notification_type h3 {
  font-size: 15px;
}

.preference_notification_type p {
  font-size: 15px;
  color: grey;
}

.preference_grid2_div2 {
  display: flex;
  align-items: center;
}

.preference_grid2_div2_innerd {
  display: flex;
  align-items: center;
  gap: 10px;
}

.preference_grid2_div2_innerd2 {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 30px;
  height: 50px;

  input,
  h3 {
    margin: 0 !important;
    padding: 0 !important;
  }
}

.preference_grid2_div2 h3 {
  font-size: 13px;
}

.preference_grid3_mininum_price {
  border: 1px solid rgb(176, 176, 176);
  width: 120px;
  height: auto;
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
  border-radius: 3px;

  .edit-inactive {
    border: none;
    width: 50%;
    color: rgb(176, 176, 176);
  }

  .edit-active {
    border: none;
    width: 50%;
    color: rgb(0, 0, 0);
  }
}

.preference_grid3_pen {
  font-size: 11px;
  color: red;
}

.preference_grid4 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 20px;
}

.preference_grid4_div2 span {
  border: 1px solid rgb(176, 176, 176);
  align-items: center;
  padding: 8px 15px;
  font-size: 13px;
  border-radius: 5px;
  color: red;
}
