@import "../_mixins";
@import "../_variables";

.product_details_container {
  background-color: white;
  padding: 15px;
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
}

.product_image_Container {
  width: 500px;
  height: 500px;

  img {
    width: 100%;
    max-height: 100%;
    border-radius: 10px;
  }
}

.product_action_container {
  display: flex;
  justify-content: space-between;
}

.product_action_container_div1 {
  display: flex;
  align-items: center;
}

.product_action_container_div2 {
  display: flex;
  align-items: center;
}

.active {
  background-color: #f8efe6;
  border: 1px solid #d0965a;
  margin-left: 10px;
  font-size: 13px;
}

.categories {
  padding: 5px 15px;
  gap: 4px;
  border-radius: 32px;
}

.edit {
  background-color: #d0965a;
  padding: 7px 15px;
  gap: 4px;
  border-radius: 32px;
  font-weight: 100;
  margin-right: 10px;
  display: flex;
  align-items: center;
  font-size: 13px;
  color: white;
  cursor: pointer;
}

.delete {
  background-color: rgba(255, 242, 242, 0.796);
  padding: 7px 15px;
  gap: 4px;
  color: red;
  border-radius: 32px;
  font-weight: 100;
  display: flex;
  align-items: center;
  font-size: 13px;
  border: none;
  cursor: pointer;
}

.product_price {
  font-size: 25px;
}

.product_kind {
  font-size: 13px;
  color: grey;
}

.product_desc {
  display: block;
  margin-top: 10px;
  font-size: 13px;
}

.product_ingredient_container {
  display: flex;
  margin-top: 20px;
}

.product_ingredient_content {
  margin-left: 70px;
}

.product_ingredient_qty h3 {
  font-size: 15px;
  font-weight: bold;
}

.product_ingredient_content h3 {
  font-size: 15px;
  font-weight: bold;
}

.product_ingredient_qty p {
  font-size: 13px;
}

.product_ingredient_content p {
  font-size: 13px;
}
